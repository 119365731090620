.checkoutForm h3{
  margin:25px 0 10px 0;
}

.checkoutForm hr{
  margin:25px 0 35px 0;
}

.checkoutForm .invalid-input-container {

}

.checkoutForm .invalid-input {
  border: 1px solid red;
}

.checkoutForm button{
  margin-bottom:20px;
}
