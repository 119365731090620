.shippingInformation .shippingOptions {

}

.shippingInformation .shippingOptions label {
  display:inline-block;
  margin-right:25px;
}

.shippingInformation .shippingOptions input {
  margin-right: 5px;
}
