.brandContainer {
  margin: 0 0 50px 0;
  text-align: center;
}

.brandDetails td {
  text-align: left;
}

.brandDetails td img {
  margin: 0 25px 0 0;
}

.brandDetails h1 {
  font-size: 22px;
  margin: 15px 0 0 0;
  font-weight: normal;
}

.brandDetails h2 {
  font-size: 18px;
  margin: 0 0 0 0;
  font-weight: normal;
}

.brandDetails h3 {
  margin: 0 0 25px 0;
  font-weight: normal;
  font-size: 11px;
}

.brandDetails .sampleImage {
  margin-bottom: 25px;
}

.brandDetails .description {
  text-align: left;
}

.brandDetails .description p {
  margin-bottom: 10px;
}

.brandDetails .description ul {
  text-align: left;
  margin: 15px 0 15px 15px;
}
