.errorContainer {
  color: #555;
  border-radius: 5px;
  padding: 20px 20px 20px 36px;
  background: #ffecec url("./error.png") no-repeat 10px 50%;
  border: 1px solid #f5aca6;
}

.errorContainer span {
  font-weight: bold;
  text-transform: uppercase;
}
