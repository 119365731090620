.quantitySelector button:active {
  background-color: rgba(14, 141, 199, 1);
}

.quantitySelector a {
  color: #0074d9;
}

.quantitySelector .additionalPacks {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 15px 0 15px 0;
  margin: 0 0 15px 0;
}

.quantitySelector .additionalPacks label {
  margin: 0 0 0 0;
}

.quantitySelector .additionalPacks button {
  padding: 0 15px;
  float: none;
  display: inline;
  width: auto;
  margin: 0 0 15px 0;
  height: auto;
  line-height: 40px;
}

.quantitySelector .cartReset {
  text-align: center;
  margin: -10px 0 10px 0;
}

.quantityChewy {
  display: block;
  margin-top: -7px;
}

.quantityChewy button {
  width: 50px;
  border-bottom: none;
}

.quantityChewy input {
  float: left;
  height: 48px;
  width: 50px;
  text-align: center;
  border: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}
