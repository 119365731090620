* {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
}

body {
  background: #f6f9fc;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: 100%;
  padding: 0 0 100px 0;
}

#root {
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
}

form {
  margin: 0 auto;
}

a {
  color: #6a7c94;
  text-decoration: underline;
}

label {
  position: relative;
  color: #6a7c94;
  font-weight: 400;
  height: 48px;
  line-height: 48px;
  margin-bottom: 10px;
  display: block;
}

label > span {
  float: left;
}

.field {
  background: white;
  box-sizing: border-box;
  font-weight: 400;
  border: 1px solid #cfd7df;
  border-radius: 5px;
  color: #32315e;
  outline: none;
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  cursor: text;
  width: 74%;
  float: right;
}

.userInformation .field,
.emailInput .field,
.billingInformation .field,
.shippingInformation .field,
.cardElementWrapper .field {
  width: 100%;
}

.userInformation span,
.emailInput span,
.billingInformation span,
.shippingInformation span {
  display: none;
}

.field::-webkit-input-placeholder {
  color: #cfd7df;
}
.field::-moz-placeholder {
  color: #cfd7df;
}
.field:-ms-input-placeholder {
  color: #cfd7df;
}

.field:focus,
.field.StripeElement--focus {
  border-color: #f99a52;
}

.cardElementWrapper .field {
  padding-top: 15px;
}

button {
  float: left;
  display: block;
  background: rgb(14, 141, 199);
  border: 0;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  height: 48px;
  line-height: 48px;
  outline: none;
  cursor: pointer;
}

button:active {
  background: rgba(14, 141, 199, 0.8);
}

.outcome {
  float: left;
  width: 100%;
  padding-top: 8px;
  min-height: 20px;
  text-align: center;
}

.success,
.error {
  display: none;
  font-size: 13px;
}

.success.visible,
.error.visible {
  display: inline;
}

.error {
  color: #e4584c;
}

.success {
  color: #f8b563;
}

.success .token {
  font-weight: 500;
  font-size: 13px;
}

.tdAlignTop {
  vertical-align: top;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}
input::-webkit-input-placeholder {
  color: #777 !important;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #777 !important;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #777 !important;
}

input:-ms-input-placeholder {
  color: #777 !important;
}

.testModeContainer {
  border-bottom:3px solid red;
  background:red;
  color:#FFF;
  font-weight:bold;
  font-size:18px;
  padding:25px;
  text-align:center;
}

.errorContainer {
  color: #555;
  border-radius: 5px;
  padding: 20px 20px 20px 36px;
  background: #ffecec url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACj0lEQVR4XqVTW08TURCe0253AySilEu1BjHYcNEYoxYLbdpCKBC5NJRY1PCAISak8S8Y/wYvPBkjkcTICzE+mJh4A3xUtksbCWp3aWEp3YW2hKXj2dYWQd/4kpMz+81+38zO2UMQEU4C5jgRJYTjOJgAhrlntFrrKFV5kEjwQMiMQTPOWhVF/ltwpIOfFYzfUFszVdY/ZDFdvgKE5QoJTYN9gYfMm9cyJBX/uVj8Q1FjKAY/qsqdTGvLC9Lbb1GVXcilUpCTNyG3JUNOVUBVdgC93WZjk+2taKv3HzGInT9lZixn5w4am1jG5YW6x09g49MCaDIVJ7dg4+NnMIceAevoAK2+gSUWy1TUZiu0h4jwy1o7GR/owWifDzOCgDoykQgKvT6MUE79slTgaO67fwATY0GM3bgaolTBINZ88V1ieADjgUEU3C7MrKygjmw0gupSURzGcJcH4+P3MfFgDEW34/2hQUujFLvWiqKnHcUeD/LtbZiNrGARmTCPvLMdJVpkfagPpW43il2uZV1bGCLHJcFgANzdBaTDQ1UFbXsbitCL4N4eHawCuXQakL4LLHvm8BQ4VqYm+g6prAYXnj6Dipt2oJXpCkNZcws0UC6VzuhCIBynH7FQcpc8t0KitwMF+3VMf/uKOtI8j8sOO/KONj0ucSseJ0ojQygGhydLn7BTXjVNHUUTYwR1cTFfdW1iHE7XVkOlpQbWHk4AHSLNLYBJ74BhNnP7MFvqQF/rg71+yX87u+p10SouFGkVKTiM66OB/B7p9OCqr1N/zsaDAWdRd+QyiaMjTiOBVzSshv9DBMBQ3czLueN3oYRYIGA2meAOGshdQGiiVBIJyDR+vqNkpi/Nz+/9c5lOgt8n8IvUM0QilAAAAABJRU5ErkJggg==) no-repeat 10px 50%;
  border: 1px solid #f5aca6;
}

.errorContainer span {
  font-weight: bold;
  text-transform: uppercase;
}


.brandContainer {
  margin: 0 0 50px 0;
  text-align: center;
}

.brandDetails td {
  text-align: left;
}

.brandDetails td img {
  margin: 0 25px 0 0;
}

.brandDetails h1 {
  font-size: 22px;
  margin: 15px 0 0 0;
  font-weight: normal;
}

.brandDetails h2 {
  font-size: 18px;
  margin: 0 0 0 0;
  font-weight: normal;
}

.brandDetails h3 {
  margin: 0 0 25px 0;
  font-weight: normal;
  font-size: 11px;
}

.brandDetails .sampleImage {
  margin-bottom: 25px;
}

.brandDetails .description {
  text-align: left;
}

.brandDetails .description p {
  margin-bottom: 10px;
}

.brandDetails .description ul {
  text-align: left;
  margin: 15px 0 15px 15px;
}

.orderSummaryWrapper{
  position: relative;
  clear:both;
}
.orderSummaryWrapper h3{
  font-size:18px;
  margin:50px 0 25px 0;
  font-weight:normal;
}

.orderSummaryWrapper h4{
  font-weight:normal;
  margin-bottom:5px;
}

.orderSummaryWrapper .field, .orderSummaryWrapper label{
  background:none;
  border:none;
  height:35px;
  line-height:35px;
  margin-right:0;
}

.quantitySelector button:active {
  background-color: rgba(14, 141, 199, 1);
}

.quantitySelector a {
  color: #0074d9;
}

.quantitySelector .additionalPacks {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 15px 0 15px 0;
  margin: 0 0 15px 0;
}

.quantitySelector .additionalPacks label {
  margin: 0 0 0 0;
}

.quantitySelector .additionalPacks button {
  padding: 0 15px;
  float: none;
  display: inline;
  width: auto;
  margin: 0 0 15px 0;
  height: auto;
  line-height: 40px;
}

.quantitySelector .cartReset {
  text-align: center;
  margin: -10px 0 10px 0;
}

.quantityChewy {
  display: block;
  margin-top: -7px;
}

.quantityChewy button {
  width: 50px;
  border-bottom: none;
}

.quantityChewy input {
  float: left;
  height: 48px;
  width: 50px;
  text-align: center;
  border: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.safetyBadges{
  position:absolute;
  display:block;
  right:0;
  padding-right:25px;
  width:150px;
}


.userInformation .birthday {
  width:24.66%;
  display:inline;
  float:right;
}


.billingInformation {
  
}

.infoContainer {
  color: #00529b;
  background-color: #bde5f8;
  border-radius: 5px;
  padding: 20px 20px 20px 36px;
  background-image: url(/static/media/info.a1cd5088.png);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 16px 16px;
  border: 1px solid;
}

.infoContainer span {
  font-weight: bold;
  text-transform: uppercase;
}

.shippingInformation .shippingOptions {

}

.shippingInformation .shippingOptions label {
  display:inline-block;
  margin-right:25px;
}

.shippingInformation .shippingOptions input {
  margin-right: 5px;
}

.specialInstructions label {
  height:auto;
}

.specialInstructions .field {
  float:none;
  width:100%;
  height:150px;
  clear:both;
}

.termsAndConditions input {
  margin-right: 10px;
}

.termsAndConditions .tc-error-prompt {
  padding: 10px 10px 10px 10px;
  margin: -15px 0 35px 0;
  border: 1px solid red;
  background: red;
  color: #fff;
  border-radius: 5px;
}

.termsAndConditions a {
  text-decoration: underline;
}

.checkoutForm h3{
  margin:25px 0 10px 0;
}

.checkoutForm hr{
  margin:25px 0 35px 0;
}

.checkoutForm .invalid-input-container {

}

.checkoutForm .invalid-input {
  border: 1px solid red;
}

.checkoutForm button{
  margin-bottom:20px;
}

.orderCompleteContainer {
}

.orderCompleteContainer h1 {
  margin-bottom: 10px;
  text-align: center;
}
.orderCompleteContainer h2 {
  margin-bottom: 10px;
  font-weight: 200;
  text-align: center;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shareBlock {
  border: 1px solid #ccc;
  background: #f7f7f7;
  padding: 20px;
}

.saleCompleteContainer {
  text-align: center;
}

.checkoutContainer {
  background:#FFF;
  border:1px solid #ccc;
  border-radius:5px;
  padding:25px;
}

