.orderCompleteContainer {
}

.orderCompleteContainer h1 {
  margin-bottom: 10px;
  text-align: center;
}
.orderCompleteContainer h2 {
  margin-bottom: 10px;
  font-weight: 200;
  text-align: center;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shareBlock {
  border: 1px solid #ccc;
  background: #f7f7f7;
  padding: 20px;
}
