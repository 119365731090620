.specialInstructions label {
  height:auto;
}

.specialInstructions .field {
  float:none;
  width:100%;
  height:150px;
  clear:both;
}
