.orderSummaryWrapper{
  position: relative;
  clear:both;
}
.orderSummaryWrapper h3{
  font-size:18px;
  margin:50px 0 25px 0;
  font-weight:normal;
}

.orderSummaryWrapper h4{
  font-weight:normal;
  margin-bottom:5px;
}

.orderSummaryWrapper .field, .orderSummaryWrapper label{
  background:none;
  border:none;
  height:35px;
  line-height:35px;
  margin-right:0;
}
