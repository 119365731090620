.termsAndConditions input {
  margin-right: 10px;
}

.termsAndConditions .tc-error-prompt {
  padding: 10px 10px 10px 10px;
  margin: -15px 0 35px 0;
  border: 1px solid red;
  background: red;
  color: #fff;
  border-radius: 5px;
}

.termsAndConditions a {
  text-decoration: underline;
}
