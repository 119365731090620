.infoContainer {
  color: #00529b;
  background-color: #bde5f8;
  border-radius: 5px;
  padding: 20px 20px 20px 36px;
  background-image: url("./info.png");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 16px 16px;
  border: 1px solid;
}

.infoContainer span {
  font-weight: bold;
  text-transform: uppercase;
}
