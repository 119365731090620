* {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
  font-variant: normal;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
}

body {
  background: #f6f9fc;
  display: flex;
  align-items: center;
  min-height: 100%;
  padding: 0 0 100px 0;
}

#root {
  margin: 0 auto;
  display: flex;
  padding: 10px;
}

form {
  margin: 0 auto;
}

a {
  color: #6a7c94;
  text-decoration: underline;
}

label {
  position: relative;
  color: #6a7c94;
  font-weight: 400;
  height: 48px;
  line-height: 48px;
  margin-bottom: 10px;
  display: block;
}

label > span {
  float: left;
}

.field {
  background: white;
  box-sizing: border-box;
  font-weight: 400;
  border: 1px solid #cfd7df;
  border-radius: 5px;
  color: #32315e;
  outline: none;
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  cursor: text;
  width: 74%;
  float: right;
}

.userInformation .field,
.emailInput .field,
.billingInformation .field,
.shippingInformation .field,
.cardElementWrapper .field {
  width: 100%;
}

.userInformation span,
.emailInput span,
.billingInformation span,
.shippingInformation span {
  display: none;
}

.field::-webkit-input-placeholder {
  color: #cfd7df;
}
.field::-moz-placeholder {
  color: #cfd7df;
}
.field:-ms-input-placeholder {
  color: #cfd7df;
}

.field:focus,
.field.StripeElement--focus {
  border-color: #f99a52;
}

.cardElementWrapper .field {
  padding-top: 15px;
}

button {
  float: left;
  display: block;
  background: rgb(14, 141, 199);
  border: 0;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  height: 48px;
  line-height: 48px;
  outline: none;
  cursor: pointer;
}

button:active {
  background: rgba(14, 141, 199, 0.8);
}

.outcome {
  float: left;
  width: 100%;
  padding-top: 8px;
  min-height: 20px;
  text-align: center;
}

.success,
.error {
  display: none;
  font-size: 13px;
}

.success.visible,
.error.visible {
  display: inline;
}

.error {
  color: #e4584c;
}

.success {
  color: #f8b563;
}

.success .token {
  font-weight: 500;
  font-size: 13px;
}

.tdAlignTop {
  vertical-align: top;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}
input::-webkit-input-placeholder {
  color: #777 !important;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #777 !important;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #777 !important;
}

input:-ms-input-placeholder {
  color: #777 !important;
}
